import AngleLeft from "./angle-left.icon.svg";
import AngleRight from "./angle-right.icon.svg";
import Assignment from "./assignment.icon.svg";
import Bike from "./bike.icon.svg";
import Box from "./box.icon.svg";
import CalendarHolidayJa from "./calendar-holiday-ja.icon.svg";
import Card from "./card.icon.svg";
import Change from "./change.icon.svg";
import ChatBubble from "./chat-bubble.icon.svg";
import CheckBoxOn from "./check-box-on.icon.svg";
import Check from "./check.icon.svg";
import Dashboard from "./dashboard.icon.svg";
import EyeSlash from "./eye-slash.icon.svg";
import Eye from "./eye.icon.svg";
import Flag from "./flag.icon.svg";
import HourglassFull from "./hourglass-full.icon.svg";
import InfoCircle from "./info-circle.icon.svg";
import Label from "./label.icon.svg";
import Lock from "./lock.icon.svg";
import MapMarker from "./map-marker.icon.svg";
import Moon from "./moon.icon.svg";
import Payment from "./payment.icon.svg";
import Phone from "./phone.icon.svg";
import PlusCircleFilled from "./plus-circle-filled.icon.svg";
import PlusCircle from "./plus-circle.icon.svg";
import QuestionCircle from "./question-circle.icon.svg";
import Reload from "./reload.icon.svg";
import Search from "./search.icon.svg";
import Send from "./send.icon.svg";
import Shift from "./shift.icon.svg";
import Skill from "./skill.icon.svg";
import Timer from "./timer.icon.svg";
import Times from "./times.icon.svg";
import Triangle from "./triangle.icon.svg";
import UserCircle from "./user-circle.icon.svg";
import WarningTriangle from "./warning-triangle.icon.svg";
import YenCircle from "./yen-circle.icon.svg";
export var Icon = {
    AngleLeft: AngleLeft,
    AngleRight: AngleRight,
    Assignment: Assignment,
    Bike: Bike,
    Box: Box,
    CalendarHolidayJa: CalendarHolidayJa,
    Card: Card,
    ChatBubble: ChatBubble,
    Change: Change,
    Check: Check,
    CheckBoxOn: CheckBoxOn,
    Dashboard: Dashboard,
    EyeSlash: EyeSlash,
    Eye: Eye,
    Flag: Flag,
    HourglassFull: HourglassFull,
    InfoCircle: InfoCircle,
    Label: Label,
    Lock: Lock,
    MapMarker: MapMarker,
    Moon: Moon,
    Payment: Payment,
    Phone: Phone,
    PlusCircle: PlusCircle,
    PlusCircleFilled: PlusCircleFilled,
    QuestionCircle: QuestionCircle,
    Reload: Reload,
    Skill: Skill,
    Send: Send,
    Timer: Timer,
    Times: Times,
    UserCircle: UserCircle,
    YenCircle: YenCircle,
    Shift: Shift,
    Triangle: Triangle,
    WarningTriangle: WarningTriangle,
    Search: Search
};
